.navbar {
    background-color: $white;
    /* Change background to white */
    position: relative;
    z-index: 1000;
    padding: 0;

    @media (min-width: 1281px) {
        border-top: solid 1px $grey-40;
    }
}

.navbar-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media (min-width: 1281px) {
        display: flex;
        gap: 3.125rem;
    }
}

.navbar-menu-item {
    position: relative;
    display: inline-block;
    /* Display menu items inline */
}

.navbar-menu-item a {
    color: $primary;
    /* Change link color to blue */
    text-decoration: none;
    padding: 1.125rem 0;
    display: block;
    font-size: .9375rem;
    font-weight: 600;

    @media (max-width: 1280px) {
        font-size: 1.125rem !important;
    }
}

.navbar-menu-item>a {
    @media (min-width: 1281px) {
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: .5rem;
            background-color: transparent;
        }
    }

    @media (max-width: 1280px) {
        font-size: 1.5rem !important;
        font-weight: 600;
    }
}

.navbar-menu-item a:hover,
.navbar-menu-item a:focus {
    color: $secondary;
}

.navbar-menu-item>a:hover,
.navbar-menu-item>a:focus,
.navbar-menu-item>a:focus-within,
.navbar-menu-item:hover>a,
.navbar-menu-item:focus>a,
.navbar-menu-item:focus-within>a {
    @media (min-width: 1281px) {
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: .5rem;
            background-color: $danger;
            background-image: url('../images/rectangle_11.png');
        }
    }
}

.has-submenu .submenu-toggle {
    display: none;
}

.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $white;
    /* Change background to white */
    list-style-type: none;
    margin: 0;
    padding: .5rem;
    z-index: 1000;
    @media (max-width: 1280px) {
        padding: 0;
        position: relative !important;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: .5rem;
            background-color: $danger;
            background-image: url('../images/rectangle_11.png');
        }
    }

    @media (min-width: 1281px) {
        width: max-content;
        min-width: 14.0625rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
}

.submenu-item a {
    padding: .625rem 1.25rem;
    display: block;
    color: $secondary;
    /* Change link color to blue */
    font-size: 15px;
}

.submenu-item a:hover,
.submenu-item a:focus {
    background-color: $grey-30;
    text-decoration: underline;
    @media (max-width: 1280px) {
        background-color: $white;
    }
}

.navbar-menu-item:hover .submenu,
.navbar-menu-item:focus-within .submenu {
    display: block;
}

@media (max-width: 1280px) {
    .navbar-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .navbar-menu-item {
        width: 100%;
        display: block;
        /* Use block for mobile to stack items */
    }

    .submenu {
        position: static;
        width: 100%;
    }

    .has-submenu .submenu-toggle {
        display: inline-block;
        background: none;
        border: none;
        color: #0056b3;
        /* Change toggle color to blue */
        font-size: 1.25rem;
        padding: .9375rem 1.25rem;
        position: absolute;
        right: 0;
        top: 0;
        @media (max-width: 1280px) {
            font-size: 1.5rem;
        }
    }

    .navbar-menu-item:hover .submenu,
    .navbar-menu-item:focus-within .submenu {
        display: none;
    }

    .navbar-menu-item .submenu.show {
        display: block;
    }

    .has-submenu>a::after {
        content: none;
    }
}

/* Add caret on desktop */
@media (min-width: 1281px) {
    .has-submenu>a::after {
        content: ' \F229';
        /* Caret symbol */
        margin-left: .3125rem;
        font-family: bootstrap-icons;
        font-size: .5rem;
        line-height: normal;
    }
}


.sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white; /* Adjust as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for sticky effect */
    .navbar {
        max-width: 1290px;
        margin: auto;
    }
}
