.alert {
    margin: 0;
    &.alert-primary {
        color: $white;
        background-color: $blue-400;

        a {
            color: $white;
            font-weight: 500;
        }
    }

    &.alert-secondary {
        color: $white;
        background-color: $secondary;

        a {
            color: $white;
            font-weight: 500;
        }
    }

    &.alert-danger {
        color: $white;
        background-color: $danger;

        a {
            color: $white;
            font-weight: 500;
        }
    }

    .alert-link {
        font-weight: 500;
        padding-right: 10px;
        text-decoration: none;
        padding-right: 35px;
        &:hover {
            text-decoration: underline;
        }
        &::after {
            content: "\F135"; // Unicode value for "arrow-right" icon in Bootstrap Icons
            font-family: "bootstrap-icons"; // Ensure this is the correct font family for Bootstrap Icons
            vertical-align: -0.125em;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-decoration: none;
            margin-left: 15px;
        }
    }

    .emergency-title {
        position: relative;
        &::before {
            content: "\F334";
            font-family: "bootstrap-icons"; // Ensure this is the correct font family for Bootstrap Icons
            vertical-align: -0.125em;
            margin-right: 8px;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }
}